import Image from "./Img"
import Star from "../img/thumbnails/star.png"
import HalfStar from "../img/thumbnails/half-star.png"
import EmptyStar from "../img/thumbnails/empty-star.png"
import { nanoid } from "nanoid";

export default function misc(props){
    // Another component for info of user
    return (
        <>
            <h3>Last seen: {props.seen}</h3>
            <h3>Dangerous Rating:
            <span className = "rating">
            {/* Mapped array that returns which star */}
            {props.stars.map((value) => {
                switch(value){
                    case 'Star':
                        return <Image key = {`fullStar-${nanoid()}`} source = {Star} desc = {value} />
                    case 'HalfStar':
                        return <Image key = {`halfOfStar-${nanoid()}`} source = {HalfStar} desc = {value} />
                    case 'EmptyStar':
                        return <Image key = {`emptyOfStar-${nanoid()}`} source = {EmptyStar} desc = {value} />
                    default:
                        return null;
                }
            })}
            </span>
            &nbsp;
            {props.rating} {props.ratingStats}
            </h3>
        </>
    );
}