import React from 'react';

export default function About(){
    // Subpage
    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "abut">
                <p>Pepega Profiles is a website to further support the Pepega mod Team and the Pepega Mod Community.</p>
            </div>
        </div>
    );
}