import React from 'react';
import { useHistory } from 'react-router-dom';
import TitleBar from "../.././components/title";
import Image from "../.././components/Img";
import Bio from "../../components/bio";
import ArchivedQuotes from "../../components/oldquotes";
import OtherInfo from "../../components/misc";
import DustinEden from "../../img/thumbnails/dustineden.jpg";
import SkylineR34 from "../../img/thumbnails/skyline-r34.jpg";

export default function Eden(){
    const history = useHistory();
    // Custom information
    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "dEden">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <TitleBar attr = "Streaming License"/>
                <div className = "grido-mode">
                    <div>
                        <Image source = {DustinEden} desc = "DustinEden PFP" />
                    </div>
                    <div>
                        <Bio uName = "Dustin"
                            tag = "@DustinEden"
                            status = "A Right-Hand Man and Streamer"
                            quote = "Oooh oooh ooh oooh! Close to missing the finish line there!"
                            description = "DustinEden was Kuru's former right-hand man and video editor. He is a professional at Midnight Club."
                        />
                    </div>
                    <div>
                        <Image source = {SkylineR34} desc = "Golf" wide = "230" stretch = "150"/>
                    </div>
                </div>
                <ArchivedQuotes title = "Old Quotes Worth Catching in 4K:"
                    vals = {["Did you know that we've almost cancelled producing Pepega Mod for this game? It was because we lost our files and we were so close to giving up. But we didn't!",
                        "Fast and Furious Crossroads is utter garbage!",
                    ]}
                />
                <OtherInfo seen = "Driving in Fast and Furious Crossroads"
                    stars = {['Star', 'Star', 'Star', 'Star', 'Star']}
                    rating = '(5 / 5)'
                    ratingStats = 'RECKLESS IMPENETRABLE FORCE'
                    warrants = "UNKNOWN"
                />
            </div>
        </div>
    );
}