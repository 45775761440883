import { nanoid } from "nanoid";

export default function oldquotes(props){
    // Array of quotes
    return (
        <div>
            <h3>{props.title}</h3>
            {props.vals.map((value) => {
                return <p key = {`theOldQuotes-${nanoid()}`}>{value}</p>
            })}
        </div>
    );
}