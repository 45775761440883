
export default function Img(props){
    // Custom image config
    if (props.wide != null && props.stretch != null){
        return (
            <img src = {props.source} alt = {props.desc} width = {props.wide} height = {props.stretch}/>
        );
    }
    else {
        return (
            <img src = {props.source} alt = {props.desc} />
        );
    }
}