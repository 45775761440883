import React from 'react';
import { useHistory } from 'react-router-dom';
import Virtual from "../.././img/thumbnails/virtual.jpg";
import CarSports from "../.././img/thumbnails/carsports.jpg";
import Bio from "../../components/bio"
import TitleBar from "../../components/title"
import Image from "../../components/Img"
import ArchivedQuotes from "../../components/oldquotes"
import OtherInfo from "../../components/misc"

export default function Wirtual(){
    const history = useHistory();
    // Custom information
    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "wirtual">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <TitleBar attr = "Cheat Engine Stats" />
                <div className = "grido-mode">
                    <div>
                        <Image source = {Virtual} desc = "Wirtual PFP" />
                    </div>
                    <div>
                        <Bio uName = "Wirtual"
                            tag = "@wirtualtm"
                            status = "Trackmania Speedrunner and Pulls a Wirtual"
                            quote = "But then...Hefest got...this run."
                            description = "Wirtual is a world-class SIGMA Trackmania player. Some say he became Mufat for a day and got second in the Cup of the Day! Don't underestimate him as he can beat the competition blindfolded! Just do yourself a favor and make sure he misses every jump possible!"
                        />
                    </div>
                    <div>
                        <Image source = {CarSports} desc = "CarSports" wide = "230" stretch = "150"/>
                    </div>
                </div>
                <ArchivedQuotes title = "Old Quotes Worth Uberbugging:"
                    vals = {["Don't worry! We've got plenty of butter! We are not in a Norwegian Butter Crisis!",
                        "Guys! I think I got Maji's account banned because I'm too good of a Trackmania player against Maji!",
                    ]}
                />
                <OtherInfo seen = "Stadium driving Recklessly, leaving skid marks"
                    stars = {['Star', 'Star', 'Star', 'HalfStar', 'EmptyStar']}
                    rating = '(3.5 / 5)'
                    ratingStats = 'INTIMIDATING BUGSLIDER'
                    warrants = 'Squeaky Clean'
                />
            </div>
        </div>
    );
}