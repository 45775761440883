import React, { useState } from "react";

// To add incident
export default function Wrntsform(props){
    const [ theIncidents, logTheIncidents ] = useState([]);

    function handleChange(e){
        logTheIncidents(e.target.value);
    }

    function handleSubmit(e){
        e.preventDefault();
        props.addIncident(theIncidents);
        logTheIncidents("");
    }

    return (
        <div className = "pepega-wrnt-form-back">
            <h1>Pepega Warrants</h1>
            <form id = "pepega-warrant-form" onSubmit = {handleSubmit}>
            <label htmlFor = "new-incident-input">Punish this sussy person: </label>
                <input type = "text" id = "new-incident-input" name = "text" autoComplete = "off" value = {theIncidents} onChange = {handleChange}/>
                <button type = "submit">
                    Add
                </button>
            </form>
            <p>{props.incident}</p>
        </div>
    );
}