import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import pfp from "../../img/thumbnails/bitmoji-ax20.png"
import m5 from "../../img/thumbnails/vitali-adutskevich-QflfBRToH-M-unsplash.jpg"
import TitleBar from "../.././components/title"
import Image from "../.././components/Img"
import Bio from "../../components/bio"
import ArchivedQuotes from "../../components/oldquotes"
import OtherInfo from "../../components/misc"

export default function AX20(){
    const history = useHistory();
    const [ tags, updateTags ] = useState([]);

    // Fetch from API json file
    useEffect(() => {
        fetch("protags.json")
        .then(response => response.json())
        .then(data => {
            let arr = [];
            for (let i = 0; i < data.length; i++){
                arr.push(data[i].custTags);
                updateTags(arr);
            }
        });
    }, []);

    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "ashwx20">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <TitleBar attr = "BMW Gift Card" />
                <div className = "grido-mode">
                    <div>
                        <Image source = {pfp} desc = "Pic" />
                    </div>
                    <div>
                        <Bio uName = "Ashwin"
                            tag = "@ax20"
                            status = "Racer, Infiltration and Escape Artist"
                            quote = "I got Global Elite without even trying!"
                            description = "ax20 or ashwin is a living organism feared by all! His driving and programming skills are top-notch that it'll kick your teeth in. Don't be fooled, dude's gone rogue so make sure to smoke'em and his estore @ https://jimbei.xyz/!"
                            isPremium = "true"
                        />
                    </div>
                    <div>
                        <Image source = {m5} desc = "M5" />
                    </div>
                </div>
                <h3>Custom Tags: </h3>
                <p>{tags[0]}<br/>{tags[1]}<br/>{tags[2]}</p>
                <ArchivedQuotes title = "Old Quotes Giving Railway a Run for Their Money:"
                    vals = {["LFA is a sexy beast!"]}
                />
                <OtherInfo seen = "Driving 400 km/h in Palm City."
                    stars = {['Star', 'Star', 'Star', 'Star', 'EmptyStar']}
                    rating = '(4 / 5)'
                    ratingStats = 'FiveHead Goober'
                    warrants = 'Multiple'
                />
            </div>
        </div>
    );
}