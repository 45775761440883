
export default function bio(props){
    // Conditional to check if user is a premium member
    // Anything else is info gathered from props and rendered
    if (props.isPremium === "true"){
        return(
            <div>
                <h2>{props.uName} <button title = "This user is a Premium Member" className = "prem-sub">Premium</button></h2>
                <h4>{props.tag}</h4>
                <h3>{props.status}</h3>
                <p>{props.quote}</p>
                <p>{props.description}</p>
            </div>
        );
    }
    else {
        return (
            <div>
                <h2>{props.uName}</h2>
                <h4>{props.tag}</h4>
                <h3>{props.status}</h3>
                <p>{props.quote}</p>
                <p>{props.description}</p>
            </div>
        );
    }
}