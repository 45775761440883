import { useEffect, useRef, useState } from "react";

function usePrevious(value){
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

// This is the info that you see on Pepega Warrants, which is a list of incidents
export default function Wrnts(props){
    // Case 1: Drinking and Driving
    // Case 2: Impeding a Driver due to Overlimit Size of React Components
    // Case 3: Profanity
    // Case 4: Applied Damage to Human Hitbox
    // Case 5: Impersonating
    // Case 6: Cancelled
    // Case 7: Being a Goober
    const [isEditing, setEditing] = useState(false);
    const [newIncident, setNewIncident] = useState('');
    const editFieldRef = useRef(null);
    const editButtonRef = useRef(null);
    const wasEditing = usePrevious(isEditing);

    function handleChange(e){
        setNewIncident(e.target.value);
    }

    function handleSubmit(e){
        e.preventDefault();
        props.editIncident(props.id, newIncident);
        setNewIncident("");
        setEditing(false);
    }

    // When user clicks on edit
    const editingTemplate = (
        <form onSubmit = {handleSubmit}>
            <div>
                <label htmlFor = {props.id}>
                    Editing incident title: &nbsp;
                </label>
                <input id = {props.id} type = "text" value = {newIncident} onChange = {handleChange} ref = {editFieldRef} />
            </div>
            <div>
                <div>
                    <button type = "button" onClick = {() => setEditing(false)}>
                        Cancel
                    </button>

                    <button type = "submit">
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
    // Normal view
    const viewTemplate = (
        <div>
            <div>
                <input id = {props.id} type = "checkbox" defaultChecked = {props.cleared} onChange = {() => props.toggleIncidentClear(props.id)}/>
                <label htmlFor = {props.id}>
                    {props.incident}
                </label>
            </div>
            <div>
                <button type = "button" onClick = {() => setEditing(true)} ref = {editButtonRef}>
                    Edit
                </button>
                <button type = "button" onClick = {() => props.deleteIncident(props.id)}>
                    Delete
                </button>
            </div>
        </div>
    );
    // Conditional Rendering
    useEffect(() => {
        if (!wasEditing && isEditing) {
            editFieldRef.current.focus();
        }
        if (wasEditing && !isEditing) {
            editButtonRef.current.focus();
        }
    }, [wasEditing, isEditing]);
    return <li>{isEditing ? editingTemplate : viewTemplate}</li>;
}