import React from 'react';
import { useHistory } from 'react-router-dom';
import GoKart from '../.././img/thumbnails/david-armstrong-srpE-a5W418-unsplash.jpg'
import Cr1tikal from '../.././img/thumbnails/Cr1tikal.jpg'
import Bio from "../../components/bio"
import TitleBar from "../../components/title"
import Image from "../../components/Img"
import ArchivedQuotes from "../../components/oldquotes"
import OtherInfo from "../../components/misc"


export default function Penguinz0(){
    const history = useHistory();
    // Custom information
    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "moistcritikal">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <TitleBar attr = "Passport" />
                <div className = "grido-mode">
                    <div>
                        <Image source = {Cr1tikal} desc = "Moist Critical" />
                    </div>
                    <div>
                        <Bio uName = "Charles White"
                            tag = "@moistcr1tikal"
                            status = "An Internet Sensation and Hilarious Goober"
                            quote = "It was a misinput MISINPUT! YOU CALM DOWN! IT WAS A MISINPUT! Gosh people are overreacting in this game."
                            description = "MoistCr1tikal or commonly referred to as, Penguinz0 on YouTube, is the greatest streamer of All Time. He likes posting media content and regularly streams on Twitch. He enjoys playing certain games like Rocket League and would even do anything to shoot his own teammates in battle! Make sure to pop his tires while you are at it!"
                        />
                    </div>
                    <div>
                        <Image source = {GoKart} desc = "Kart GO NYOOM" wide = "230" stretch = "150"/>
                    </div>
                </div>
                <ArchivedQuotes title = "Old Quotes Worth Getting a Fat L:"
                    vals = {["We caught you on a camera and I'm like: I like to create, I like to inspire and show unedited photos of myself...(mickey mouse clubhouse dance)",
                        "WOOO! YEAH BABY!"
                    ]}
                />
                <OtherInfo seen = "Twitch"
                    stars = {['Star', 'Star', 'Star', 'Star', 'HalfStar']}
                    rating = "(4.5 / 5)"
                    ratingStats = "SUSSY MODE BEHAVIOUR"
                    warrants = "None at the moment. 👀👀👀"
                />
            </div>
        </div>
    );
}