import { nanoid } from "nanoid";

export default function Custlstagsprint(props){
    return (
        <div>
            <div>
                {(props.tags != null) ? props.tags.map((element) => {
                    return <p key = {`custlstags-${nanoid()}`}>{element}</p>;
                }) : props.tags}
            </div>
        </div>
    );
}