import { useState } from "react";

// Initially was supposed to be tags but changed to alias
export default function Custlstags(props){
    const [ taggy, updateTaggy ] = useState([]);

    // Whenever user has typed something, it updates taggy state immediately
    function handleChange(e){
        updateTaggy(e.target.value);
    }

    // Creates a new array, adds new info to it, updates the taggy state and the actual state that renders the info
    function handleSubmit(e){
        e.preventDefault();
        let arr = [];
        if (props.tags != null){
            for (let i = 0; i < props.tags.length; i++){
                arr.push(props.tags[i]);
            }
        }
        else {
            arr = [];
        }
        arr.push(taggy);
        props.updateTags(arr);
        updateTaggy("");
    }

    // Simply clears all of the credentials
    function handleClear(){
        localStorage.clear();
        props.updateTags([]);
        updateTaggy([]);
    }

    return(
        <div>
            <form id = "pepega-tags" onSubmit = {handleSubmit}>
                <label htmlFor = "new-tags-input">Add alias: </label>
                <input type = "text" id = "new-tags-input" name = "text" value = {taggy} onChange = {handleChange} autoComplete = "off"/>
                <button type = "submit">Commit</button>
                <button onClick = {() =>{handleClear()}}>Clear</button>
            </form>
        </div>
    );
}