import React from 'react';
import { useHistory } from 'react-router-dom';
import Bio from "../../components/bio";
import TitleBar from "../../components/title";
import Image from "../../components/Img";
import ArchivedQuotes from "../../components/oldquotes";
import OtherInfo from "../../components/misc";
import NeutralFace from "../../img/thumbnails/neutral-face_1f610.gif";
import AudiR8 from "../../img/thumbnails/audi-r8.jpg";

export default function AReza(){
    const history = useHistory();
    // Custom information
    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "arez">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <TitleBar attr = "GitHub ID" />
                <div className = "grido-mode">
                    <div>
                        <Image source = {NeutralFace} desc = "areza PFP" />
                    </div>
                    <div>
                        <Bio uName = "areza"
                            tag = "@areza"
                            status = "Professor and Programmer by Day, Hacker and Racer at Night"
                            quote = "useState is a hook in React that persists the state."
                            description = "areza is someone you shouldn't mess with! You will never be safe if you see him. He always comes up with something and is always prepared in any circumstance. Some say he got a Hennessey but sold it because it was too boring to drive! Spec out your ride as hard as you can and prove to him that your ride isn't all about looks."
                            isPremium = "true"
                        />
                    </div>
                    <div>
                        <Image source = {AudiR8} desc = "" wide = "230" stretch = "150"/>
                    </div>
                </div>
                <ArchivedQuotes title = "Old Quotes Worth Scoring an A+:"
                    vals = {["Make sure to deploy your website to Netlify in the end!",
                        "So today we're going to be talking about React Router.",
                    ]}
                />
                <OtherInfo seen = "A College Running Away from Students"
                    stars = {['Star', 'Star', 'Star', 'Star', 'Star']}
                    rating = '(5 / 5)'
                    ratingStats = 'PASSIVE DESTROYER'
                    warrants = 'Squeaky Clean'
                />
            </div>
        </div>
    );
}