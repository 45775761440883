import './App.css';
import { Route, Switch , useLocation} from 'react-router-dom';
import Header from "./components/Footer";
import Sources from "./pages/sources";
import About from "./pages/about";
import Home from "./pages/Home";
import ENTPrestigious from './pages/subpages/entprestigious';
import AX20 from './pages/subpages/ax20';
import moistcritical from './pages/subpages/penguinz0';
import KuruHS from './pages/subpages/kuru';
import VirtualVibes from './pages/subpages/wirtual';
import AReza from "./pages/subpages/areza";
import DustinEden from "./pages/subpages/eden";
import { useEffect } from 'react';
import ENTPBCKGRND from "./img/background/outerspace-6.gif";
import AX20BCKGRND from "./img/background/sunset-cruise.gif";
import PENGUINZ0BCKGRND from "./img/background/igor-karimov-9AmKnNZw3GA-unsplash.jpg";
import KURUBCKGRND from "./img/background/art-of-rally.1920x1080.jpg";
import WIRTUALBCKGRND from "./img/background/tm-stadium.jpg";
import SOURCEBCKGRND from "./img/background/rodion-kutsaiev-PEm_sLmJT-w-unsplash.jpg";
import ABOUTBCKGRND from "./img/background/nastuh-abootalebi-eHD8Y1Znfpk-unsplash.jpg";
import AREZABCKGRND from "./img/background/hacker-bckgrnd.gif";
import EDENBCKGRND from "./img/background/car-chase.jpg";

function App() {
  // Get subpage location from react router using useLocation
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  useEffect(() => {
    // Apply proper styles and background image to body by subpage
    switch(location){
      case "entprestigious":
        document.body.style.backgroundImage = `url('${ENTPBCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "ax20":
        document.body.style.backgroundImage = `url('${AX20BCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "moistcr1tikal":
        document.body.style.backgroundImage = `url('${PENGUINZ0BCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "kuruhs":
        document.body.style.backgroundImage = `url('${KURUBCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "wirtualtm":
        document.body.style.backgroundImage = `url('${WIRTUALBCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "areza":
        document.body.style.backgroundImage = `url('${AREZABCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "dustineden":
        document.body.style.backgroundImage = `url('${EDENBCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "sources":
        document.body.style.backgroundImage = `url('${SOURCEBCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      case "about":
        document.body.style.backgroundImage = `url('${ABOUTBCKGRND}')`;
        document.body.style.backgroundAttachment = "fixed";
        document.body.style.backgroundRepeat = "no-repeat";
        document.body.style.backgroundPosition = "center";
        document.body.style.backgroundSize = "cover";
        break;
      default:
        document.body.style.backgroundImage = null;
        break;
    }
  });
  return (
    <div id = "main-cont">
        {/* Routes to different pages */}
        <Switch>
          <Route path = "/" component = {Home} exact />
          <Route path = "/sources" component = {Sources} exact />
          <Route path = "/about" component = {About} exact/>
            <Route path = "/entprestigious" component = {ENTPrestigious} id = "ELITEPRESTIGIOUS" exact/>
            <Route path = "/ax20" component = {AX20} exact/>
            <Route path = "/moistcr1tikal" component = {moistcritical} exact/>
            <Route path = "/kuruhs" component = {KuruHS} exact/>
            <Route path = "/wirtualtm" component = {VirtualVibes} exact/>
            <Route path = "/areza" component = {AReza} exact/>
            <Route path = "/dustineden" component = {DustinEden} exact/>
        </Switch>
      <Header />
    </div>
  );
}

export default App;
