import React from 'react';
import { Link } from 'react-router-dom';

export default function Header(){
    // Was a header at the top as a component
    // but now, it's the taskbar that you see at the bottom
    return (
        <>
        <footer>
        <div>
            <h1>Pepega Profiles</h1>
            <small>Copyright &copy; 2022 NOTORIEX LEGACY. All Rights Reserved.</small>
        </div>
            <ul>
                <Link to = "/"><li title = "Return to your safehouse and chill out when the heat's on!">Safehouse</li></Link>
                <Link to = "/sources"><li title = "Some stolen assets!">Stolen</li></Link>
                <Link to = "/about"><li title = "Our about page!">Leaked</li></Link>
            </ul>
        </footer>
        </>
    );
}