import React from 'react';
import '.././App.css';
import { Link } from 'react-router-dom';

export default function Sources(){
    // Simply sources! Sorry if I've missed some!
    return(
        <div className = "custom-back">
            <div className = "wrapper" id = "sorce">
                <table>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Link</th>
                        </tr>
                        <tr>
                            <td>Pepega Colour Scheme</td>
                            <td><Link to = "https://pepegamod.com/">Pepega Mod</Link></td>
                        </tr>
                        <tr>
                            <td>Custom Vars</td>
                            <td><Link to = "https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties">MDN CSS Custom Properties</Link></td>
                        </tr>
                        <tr>
                            <td>Go Back Event</td>
                            <td><Link to = "https://stackoverflow.com/questions/30915173/react-router-go-back-a-page-how-do-you-configure-history">Stack Overflow</Link></td>
                        </tr>
                        <tr>
                            <td>Thumbnails and Images from Conversations</td>
                            <td><Link to ="https://discord.com/">Discord</Link><br /><Link to ="https://facebook.com/">Facebook</Link></td>
                        </tr>
                        <tr>
                            <td>Backdrop filter blur</td>
                            <td><Link to = "https://css-tricks.com/almanac/properties/b/backdrop-filter/">CSS Tricks</Link></td>
                        </tr>
                        <tr>
                            <td>Star Icons by Pixel Perfect</td>
                            <td><Link to = "https://flaticon.com">FlatIcon</Link></td>
                        </tr>
                        <tr>
                            <td>Golf GTI</td>
                            <td><Link to = "https://www.deviantart.com/1997deviantivan/art/NFS-Most-Wanted-Kuru-s-Golf-893827537">Deviant Art</Link></td>
                        </tr>
                        <tr>
                            <td>Art of Rally Background</td>
                            <td><Link to = "https://www.google.com/search?q=art+of+rally+background&client=opera-gx&hs=QTJ&hl=en&sxsrf=ALiCzsZV7ADMI4az9p4McoCkpLOoqlHOyw:1669070189323&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjpmvX9qsD7AhVCAzQIHejNBEYQ_AUoAXoECAEQAw&biw=1879&bih=931&dpr=1#imgrc=Uy6xrcILMl0T1M">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>Trackmania CarSports</td>
                            <td><Link to = "https://www.google.com/search?q=trackmania+2020+carsports&tbm=isch&ved=2ahUKEwju_77cxMD7AhWUBjQIHeIfC9cQ2-cCegQIABAA&oq=trackmania+2020+carsports&gs_lcp=CgNpbWcQAzoECCMQJzoFCAAQgAQ6BggAEAgQHjoHCAAQgAQQGFChCljlEmDJE2gAcAB4AIABTYgB8QSSAQIxMJgBAKABAaoBC2d3cy13aXotaW1nwAEB&sclient=img&ei=ahh8Y-6iIZSN0PEP4r-suA0&bih=931&biw=1864&client=opera-gx&hl=en#imgrc=fpFRlzK33fPBQM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>KuruHS Profile Picture</td>
                            <td><Link to = "https://www.google.com/search?q=kuruhs&client=opera-gx&hs=sU1&sxsrf=ALiCzsbKicd9Vf_opZ_MeoflpR0_1gU2XA:1669080533853&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiLmcnC0cD7AhVjN30KHVWqC6QQ_AUoAXoECAIQAw&biw=1879&bih=931&dpr=1#imgrc=8p5Yv9pIGUFoKM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>Wirtual Profile Picture</td>
                            <td><Link to = "https://www.google.com/search?q=wirtual&client=opera-gx&hs=Lqg&sxsrf=ALiCzsZgejKjjUvF8o2isNxZ5GNH2I7zFg:1669080581993&source=lnms&tbm=isch&sa=X&ved=2ahUKEwikssPZ0cD7AhXnIDQIHSqICiEQ_AUoAnoECAIQBA&biw=1879&bih=931&dpr=1#imgrc=DgBIyPCvMEuHLM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>MoistCr1tikal Profile Picture</td>
                            <td><Link to = "https://www.google.com/search?q=moistcritikal&client=opera-gx&hs=aBM&sxsrf=ALiCzsZKm4ikxf7j-uXS5T8ib_YBN8uncg:1669080615059&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjSyKXp0cD7AhUtCTQIHdr3Bz8Q_AUoAXoECAEQAw&biw=1879&bih=931&dpr=1#imgrc=_3Ff_S6t8jB9EM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>GoKart by david armstrong, Controller Purple Background by igor karimov, BMW M5 by vitali adutskevich, F1/NASCAR background by sippakorn yamkasikorn, background photos for stolen and leaked</td>
                            <td><Link to = "https://unsplash.com">Unsplash</Link></td>
                        </tr>
                        <tr>
                            <td>Pepega Icon</td>
                            <td><Link to = "https://www.google.com/search?q=pepega+png&tbm=isch&chips=q:pepega+png,online_chips:transparent+background:BC2EV4WXB7Q%3D&client=opera-gx&hs=aBM&hl=en&sa=X&ved=2ahUKEwiJirWT0sD7AhVgMjQIHV5zCzUQ4lYoAXoECAEQJw&biw=1864&bih=931#imgrc=zgEwVfLy41Hu9M">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>AX20 Profile Picture (Link may not work due to persistent updates)</td>
                            <td><Link to = "https://ashwin.lol">Ashwin's Website</Link></td>
                        </tr>
                        <tr>
                            <td>Trackmania Wallpaper</td>
                            <td><Link to = "https://www.google.com/search?q=trackmania+2020+wallpaper&tbm=isch&ved=2ahUKEwijwZ2v0sD7AhXBKH0KHSbzD84Q2-cCegQIABAA&oq=trackmania+&gs_lcp=CgNpbWcQARgAMgQIIxAnMgQIIxAnMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQ6BAgAEEM6CAgAEIAEELEDOgcIABCxAxBDUIkDWJ0KYO8OaABwAHgAgAFHiAHUBZIBAjEymAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=uSZ8Y-OALMHR9AOm5r_wDA&bih=931&biw=1864&client=opera-gx&hs=aBM&hl=en#imgrc=byebhXUtO627IM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>Trackmania CarSports</td>
                            <td><Link to = "https://www.google.com/search?q=trackmania+2020+carsports&client=opera-gx&hs=BHM&sxsrf=ALiCzsY75SbFGcE6vtBelN5HlLaXjxIOAw:1669080962496&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiJvPuO08D7AhXULH0KHVi8DPYQ_AUoAXoECAIQAw&biw=1879&bih=931&dpr=1#imgrc=fpFRlzK33fPBQM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>Wooden Spoiler Rocket Race Car [ORIGINAL POST COULD BE DELETED BY OWNER]</td>
                            <td><Link to = "https://imgur.com/gallery/Tk5p09d">Imgur</Link></td>
                        </tr>
                        <tr>
                            <td>Array Props</td>
                            <td><Link to = "https://bobbyhadz.com/blog/react-pass-array-as-prop#:~:text=To%20pass%20an%20array%20as,to%20render%20the%20array%27s%20elements.">BobbyHadz</Link></td>
                        </tr>
                        <tr>
                            <td>React Loop Return</td>
                            <td><Link to = "https://flaviocopes.com/react-how-to-loop/">Flavioscopes</Link></td>
                        </tr>
                        <tr>
                            <td>Ax20 GIF Background</td>
                            <td><Link to = "https://www.pinterest.ca/pin/833447474792126062/">Pinterest</Link></td>
                        </tr>
                        <tr>
                            <td>Custom ELITE NOTORIOUS THE PRESTIGIOUS Background</td>
                            <td><Link to = "https://acegif.com/gif/outerspace-6/">AceGif</Link></td>
                        </tr>
                        <tr>
                            <td>Add Background Image from React Route</td>
                            <td><Link to = "https://stackoverflow.com/questions/67036380/changing-background-image-based-on-route-in-react">Stack Overflow</Link></td>
                        </tr>
                        <tr>
                            <td>Add Image with Import</td>
                            <td><Link to = "https://stackoverflow.com/questions/65678684/setting-body-background-image-inside-useeffect">Stack Overflow</Link></td>
                        </tr>
                        <tr>
                            <td>areza Images,</td>
                            <td><Link to = "https://images.google.com/">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>DustinEden PFP by DustinEden</td>
                            <td><Link to = "https://www.google.com/search?q=dustineden&tbm=isch&ved=2ahUKEwjnr9DsrNz7AhWwHTQIHZLDDJwQ2-cCegQIABAA&oq=dustineden&gs_lcp=CgNpbWcQAzIECCMQJzIHCAAQgAQQGDoGCAAQBxAeOgUIABCABDoICAAQgAQQsQM6BAgAEEM6BAgAEB46CQgAEIAEEAoQGFCqA1jPEGDZEWgBcAB4AIABTIgBhgaSAQIxMpgBAKABAaoBC2d3cy13aXotaW1nwAEB&sclient=img&ei=eq2KY-eKJbC70PEPkoez4Ak&bih=931&biw=1864&client=opera-gx&hs=CYg&hl=en#imgrc=kcHJbJS24OYt_M">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>Skyline</td>
                            <td><Link to = "https://www.google.com/search?q=skyline+r34&tbm=isch&ved=2ahUKEwjFmaSWrdz7AhV3BzQIHQLfDToQ2-cCegQIABAA&oq=skyline+r34&gs_lcp=CgNpbWcQAzIECCMQJzIECCMQJzIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEOgYIABAHEB46BAgAEB46BggAEAgQHlC8CFj4CWCRC2gAcAB4AIABSYgBsgGSAQEzmAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=0a2KY4XtOveO0PEPgr630AM&bih=931&biw=1864&client=opera-gx&hs=CYg&hl=en#imgrc=JUVbfAXZ2pagdM">Google Images</Link></td>
                        </tr>
                        <tr>
                            <td>Car Chase</td>
                            <td><Link to = "https://www.google.com/search?q=car%20chase%20background&tbm=isch&tbs=isz:l&client=opera-gx&hs=CYg&hl=en&sa=X&ved=0CAIQpwVqFwoTCKDzzums3PsCFQAAAAAdAAAAABAI&biw=1864&bih=931#imgrc=sfWGXBtqH3cytM">Google Images</Link></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}