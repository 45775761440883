import { nanoid } from "nanoid";

export default function Custoldquotes(props){
    return (
        <div>
            <div>
                {(props.quoty != null) ? props.quoty.map((element) => {
                    return <p key = {`theQuotes-${nanoid()}`}>{element}</p>;
                }) : props.quoty}
            </div>
        </div>
    );
}