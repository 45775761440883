import React , {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import CreepySmile from "../.././img/thumbnails/creepysmile.webp";
import RaceCar from "../.././img/thumbnails/racecar.jpg";
import Bio from "../../components/bio";
import Image from "../../components/Img";
import ArchivedQuotes from "../../components/oldquotes";
import OtherInfo from "../../components/misc";
import PepegaWarrantsForm from "../../components/wrntsform";
import PepegaWarrants from "../../components/wrnts";
import { nanoid } from "nanoid";
import FilterButton from "../../components/FilterButton";
import LSAliasForm from "../../components/custlstags";
import LSAlias from "../../components/custlstagsprint";
import LSOldQuotesForm from "../../components/storeoldquotesform";
import LSOldQuotes from "../../components/custoldquotes";

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

const DATAVAL = [
    {id: 1, incident: "Drinking and Driving", cleared: false},
    {id: 2, incident: "Cancelled", cleared: false},
    {id: 3, incident: "Impeding a Driver due to Overlimit Size of React Components", cleared: false},
];

const FILTER_MAP = {
    All: () => true,
    Cleared: (incident) => incident.cleared
};

const FILTER_NAMES = Object.keys(FILTER_MAP);

const UserContext = React.createContext();

export default function ENTPrestigious(){
    const history = useHistory();
    const [ nickNames, updateNick ] = useState([]);

    const [ quotes, updateQuotes ] = useState([]);
    const [ myWarrants, updateWarrants ] = useState(DATAVAL);
    const [ filter, setFilter ] = useState('All');
    const [ tags, updateTags ] = useState([]);
    // Fetch API from Names.json in PUBLIC
    useEffect(() => {
        fetch("Names.json")
        .then(response => response.json())
        .then(data => {
            let arr = [];
            for (let i = 0; i < data.length; i++){
                arr.push(data[i].custName);
                updateNick(arr);
            }
        });
    }, []);

    // Gets alias
    useEffect(() => {
        const init = localStorage.getItem('tags');
        if (init){
            updateTags(JSON.parse(init));
        }
    }, []);

    // Sets alias
    useEffect(() => {
        localStorage.setItem('tags', JSON.stringify(tags));
    });

    // Gets Cust Quotes
    useEffect(() => {
        const init = localStorage.getItem("quotes");
        if (init){
            updateQuotes(JSON.parse(init));
        }
    },[]);

    // Sets Cust Quotes
    useEffect(() => {
        localStorage.setItem("quotes", JSON.stringify(quotes));
    });

    // Updates the myWarrants state with a new incident
    function addIncident(incident){
        alert(incident);
        const newWarrant = {id: `todo-${nanoid()}`, incident, cleared: false};
        updateWarrants([...myWarrants, newWarrant]);
    }

    // Grabs the list of myWarrants, gets rid of the deleted one, updates the state with it being deleted
    function deleteIncident(id){
        const remainingWarrants = myWarrants.filter((incident) => id !== incident.id);
        console.log("TEST");
        updateWarrants(remainingWarrants);
    }

    // Switch to check if the incident has been cleared or paid by user
    function toggleIncidentClear(id){
        const updatedWarrants = myWarrants.map((incident) => {
            // if this incident has the same ID as the edited incident
            if (id === incident.id){
                return {...incident, cleared: !incident.cleared}
            }
            return incident;
        });
        updateWarrants(updatedWarrants);
    }

    // Creates array of incidents of myWarrants
    const wrntList = myWarrants.filter(FILTER_MAP[filter])
                    .map((val) => (
        <PepegaWarrants incident = {val.incident} id = {val.id} cleared = {val.cleared} key = {val.id} toggleIncidentClear = {toggleIncidentClear} deleteIncident = {deleteIncident} editIncident = {editIncident}/>
    ));

    // Array of filtered features as in if a user has cleared an incident
    const filterList = FILTER_NAMES.map((incident) => (
        <FilterButton key = {incident} incident = {incident} isPressed = {incident === filter}
        setFilter = {setFilter}/>
    ));

    // Plural or singular test
    const incidentChecky = (wrntList.length !== 1) ? 'Criminal Records' : 'Criminal Record';
    const numberOfIncidents = `${wrntList.length} ${incidentChecky} Filed`;

    // Edit functionality to incident
    function editIncident(id, newIncident){
        const editedIncidentList = myWarrants.map((theIncident) => {
            if (id === theIncident.id){
                return {...theIncident, incident: newIncident}
            }
            return theIncident;
        });
        updateWarrants(editedIncidentList);
    }

    const listIncidentsRef = useRef(null);
    const prevIncidentLength = usePrevious(myWarrants.length);

    useEffect(() => {
        if (myWarrants.length - prevIncidentLength === -1){
            listIncidentsRef.current.focus();
        }
    }, [myWarrants.length, prevIncidentLength]);

    // Global State
    function TitleBar(){
        const value = React.useContext(UserContext);
        return (
            <h1>{value}</h1>
        );
    }

    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "prestigious">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <UserContext.Provider value = "You-Gee-HoOh! Trading Card">
                    <TitleBar/>
                </UserContext.Provider>
                <div className = "grido-mode">
                    <div>
                        <Image source = {CreepySmile} desc = "Creepy Smile" />
                    </div>
                    <div>
                        <Bio uName = "ELITE NOTORIOUS THE PRESTIGIOUS"
                            tag = "@entprestigious"
                            status = "A Built-Different Individual and Professional Goober Administrator"
                            quote = "Want to know a trick? Press ALT + F4 for $1,000,000!"
                            description = "ELITE NOTORIOUS THE PRESTIGIOUS is one of the most wanted fugitives who lives in WonkyPort City. Legends say he ran a stop sign without looking both ways. He is merciless and never checks his blinker fluids. If seen, do not come to contact. Dial 1337 immediately to notify the Renewed Technologies Inc. team! Remember to lock your doors as he likes to tickle people that are sleeping!"
                            isPremium = "true"
                        />
                    </div>
                    <div>
                        <Image source = {RaceCar} desc = "Race Car"  wide = "230" stretch = "150"/>
                    </div>
                </div>
                <h3>Custom Nicknames: </h3>
                <p>{nickNames[0]}<br/>{nickNames[1]}<br/>{nickNames[2]}</p>
                <h3>Custom Alias: </h3>
                <LSAliasForm tags = {tags} updateTags = {updateTags}/>
                <LSAlias tags = {tags}/>
                <ArchivedQuotes title = "Old Quotes Worth Demonetizing and Cancelled:"
                    vals = {["Yo I think we got a cheater man!",
                        "I dropped the lamb sauce! What do I do?",
                        "I need...somebody leave me, especially SpongeBrain",
                        "Haha you're killing me...you really are."
                    ]}
                />
                <LSOldQuotes quoty = {quotes}/>
                <LSOldQuotesForm quoty = {quotes} updateQuoty = {updateQuotes}/>
                <OtherInfo seen = "McDonalds eating a juicy Whopper (3rd-class offense) and smiling maniacally." 
                    stars = {['Star', 'Star', 'Star', 'Star', 'Star']}
                    rating = '(5 / 5)'
                    ratingStats = 'VERY DANGEROUS'
                />
                <PepegaWarrantsForm addIncident = {addIncident}/>
                <div className = "themFilters">
                    {filterList}
                </div>
                <div className = "back-pepega-warrants">
                    <h1 id = "show-head" tabIndex = "-1" ref={listIncidentsRef}>
                        {numberOfIncidents}
                    </h1>
                    <ul id = "Spicy-Warrants" aria-labelledby='show-head'>
                        {wrntList}
                    </ul>
                </div>
            </div>
        </div>
    );
}