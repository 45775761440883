import React, { useEffect , useState} from 'react';
import { Link } from 'react-router-dom';

// Homepage when you first open the page
export default function Home(){
    const [ theUsers, updateTheUsers ] = useState([]);
    const [ counter, updateCount ] = useState(0);

    // Fetches the names of users
    useEffect(() => {
        fetch("users.json")
        .then(response => response.json())
        .then(data => {
            let arr = [];
            for (let i = 0; i < data.length; i++){
                arr.push(data[i].nameuser);
                updateTheUsers(arr);
                updateCount(i + 1);
            }
        });
    }, []);

    return (
        <div className = "wrapper">
            <p>Logged in as Sergeant Crawl. Welcome to this dirt show!</p>
            <h3>Top {counter} Goofy Goobers Pepega Sheet: </h3>
            <ol>
                <li><p><Link to = "/entprestigious">{theUsers[0]}</Link></p></li>
                <li><p><Link to = "/ax20">{theUsers[1]}</Link></p></li>
                <li><p><Link to = "/moistcr1tikal">{theUsers[2]}</Link></p></li>
                <li><p><Link to = "/kuruhs">{theUsers[3]}</Link></p></li>
                <li><p><Link to = "/wirtualtm">{theUsers[4]}</Link></p></li>
                <li><p><Link to = "/areza">{theUsers[5]}</Link></p></li>
                <li><p><Link to = "/dustineden">{theUsers[6]}</Link></p></li>
            </ol>
        </div>
    );
}