import React from 'react';
import { useHistory } from 'react-router-dom';
import TwitchKuru from "../.././img/thumbnails/kuruhs-twitch-300x300.png";
import Golf from "../.././img/thumbnails/kuruhs-golf.png";
import TitleBar from "../.././components/title"
import Image from "../.././components/Img"
import Bio from "../../components/bio"
import ArchivedQuotes from "../../components/oldquotes"
import OtherInfo from "../../components/misc"

export default function Kuru(){
    const history = useHistory();
    // Custom information
    return (
        <div className = "custom-back">
            <div className = "wrapper" id = "kuruhotshots">
                <button onClick= {history.goBack}>Frick! Go Back!</button>
                <TitleBar attr = "Pink Slip"/>
                <div className = "grido-mode">
                    <div>
                        <Image source = {TwitchKuru} desc = "KuruHS Face" />
                    </div>
                    <div>
                        <Bio uName = "Kuru"
                            tag = "@KuruHS"
                            status = "Austrian Most Wanted Speedrunner and Racing Game Streamer"
                            quote = "Hey guys it's Kuru! Check out Instant Gaming for cheap game keys! Link is in the description below!"
                            description = "KuruHS is a Twitch Streamer with tons of subs and emotes! His favourite game is Need for Speed Most Wanted! He is the most Pepega driver of the race. Show him who's the real boss by wiping out that disgusting ride of his!"
                        />
                    </div>
                    <div>
                        <Image source = {Golf} desc = "Golf" wide = "230" stretch = "150"/>
                    </div>
                </div>
                <ArchivedQuotes title = "Old Quotes Worth Subscribing for $4.99:" 
                    vals = {["EVERYONE!!!", 
                        "Remember to fill up your MadMonq meter!",
                    ]}
                />
                <OtherInfo seen = "Driving an orange Volkswagen Golf GTI With a Reaper Spoiler" 
                    stars = {['Star', 'Star', 'Star', 'Star', 'Star']}
                    rating = '(5 / 5)'
                    ratingStats = 'IMMORTAL ENERGY'
                    warrants = "MANY. 👀👀👀"
                />
            </div>
        </div>
    );
}