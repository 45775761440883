import { useState } from "react";

// A small form that stores your custom quotes to localStorage
export default function Storeoldquotesform(props){
    const [ quotes, updateQuotes ] = useState([]);

    function handleChange(e){
        updateQuotes(e.target.value);
    }

    function handleSubmit(e){
        e.preventDefault();
        let arr = [];
        if (props.quoty != null){
            for (let i = 0; i < props.quoty.length; i++){
                arr.push(props.quoty[i]);
            }
        }
        else {
            arr = [];
        }
        arr.push(quotes);
        props.updateQuoty(arr);
        updateQuotes("");
    }

    function handleClear(){
        localStorage.clear();
        props.updateQuoty([]);
        updateQuotes([]);
    }

    return(
        <div>
            <form id = "pepega-oldquotes" onSubmit = {handleSubmit}>
                <label htmlFor = "new-oldquotes-input">Add quote: </label>
                <input type = "text" id = "new-oldquotes-input" name = "text" value = {quotes} onChange = {handleChange} autoComplete = "off"/>
                <button type = "submit">Stamp Above</button>
                <button onClick = {() =>{handleClear()}}>Remove Added Quotes</button>
            </form>
        </div>
    );
}